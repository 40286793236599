
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IWarehouse, IWarehouseListRequest } from "@/types/warehouse";
import { IMakerListRequest } from "@/types/maker";
import { IProductListRequest, IProduct } from "@/types/product";
import { ILot, ILotListRequest } from "@/types/lot";
import { IStock } from "@/types/stock";
import { IUser, IUserListRequest } from "@/types/user";
import {
  IMovement,
  IMovementCreateRequest,
  IMovementRequest,
  IMovementUpdateRequest
} from "@/types/movement";
import { MovementRequest } from "@/models/movement";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //movement
  @Action("movement/adminCreate")
  public create!: (params: IMovementCreateRequest) => Promise<boolean>;

  @Action("movement/adminGet")
  public getMovement!: (movement_id: number) => Promise<boolean>;

  @Action("movement/adminUpdate")
  public update!: (data: {
    movement_id: number;
    params: IMovementUpdateRequest;
  }) => Promise<boolean>;

  @Action("movement/adminUpdateQuantity")
  public updateQuantity!: (data: {
    movement_id: number;
    params: IMovementUpdateRequest;
  }) => Promise<boolean>;

  @Getter("movement/single")
  public movement!: IMovement;

  @Mutation("movement/clear")
  public clearMovement!: () => void;

  @Action("movement/adminDelete")
  public deleteMovement!: (movement_id: number) => Promise<boolean>;

  //stock
  @Action("stock/adminGet")
  public getStock!: (stock_id: number) => Promise<boolean>;

  @Getter("stock/single")
  public stock!: IStock;

  @Mutation("stock/clear")
  public clearStock!: () => void;

  //warehouse
  @Action("warehouse/adminGetList")
  public getWarehouseList!: (params: IWarehouseListRequest) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Getter("warehouse/find")
  public findWarehouse!: (id: number) => IWarehouse;

  @Mutation("warehouse/clear")
  public clearWarehouse!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetList")
  public getProductList!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //lot
  @Action("lot/adminGetList")
  public getLots!: (params: ILotListRequest) => Promise<boolean>;

  @Getter("lot/selectItem")
  public lotList!: ISelectItem[];

  @Getter("lot/find")
  public findLot!: (id: number) => ILot;

  @Mutation("lot/clear")
  public clearLot!: () => void;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Action("user/adminGetStaffList")
  public getStaffs!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/selectItemStaff")
  public staffList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  //パラメータ定義
  public params: IMovementRequest = new MovementRequest();

  //変数定義
  public movement_status = Vue.prototype.$movementStatus;
  public destinationWarehouseList: ISelectItem[] = [];
  public movement_id = 0;
  public stock_id = 0;
  public max_quantity = 0;
  public unit = "";
  public valid_years_disp = false;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public menu = {
    movement_date: false,
    arrival_date: false
  };

  //ルール設定
  public get rules() {
    return {
      warehouse_id: [(v: number) => !!v || "移動先倉庫は必須です"],
      movement_date: [(v: string) => !!v || "移動日は必須です"],
      user_id: [(v: number) => !!v || "登録者は必須です"],
      quantity: [
        (v: number) => !!v || "数量は必須です",
        (v: number) => v > 0 || "0以下は入力できません",
        (v: string) => /^([1-9]\d*|0)$/.test(v) || "整数で入力してください",
        (v: number) => v <= this.max_quantity || "在庫以上は入力できません"
      ]
    };
  }

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.stock_id = Number(this.$route.params.stock_id);
    this.movement_id = Number(this.$route.params.movement_id);
    this.clearMovement();
    this.clearStock();
    this.clearWarehouse();
    this.clearMaker();
    this.clearProduct();
    this.clearLot();
    this.clearUser();

    await Promise.all([
      this.getUsers({ per_page: 0 }),
      this.getWarehouseList({ per_page: 0 }),
      this.getProductList({ per_page: 0 })
    ]);

    // 編集時
    if (this.movement_id) {
      await this.getMovement(this.movement_id);

      this.valid_years_disp = this.diffMonth(this.movement.movement_date) > 12;
      if (this.valid_years_disp) {
        return;
      }

      await this.getStock(this.movement.stock_id);
      await this.setUpdateDefault();

      // 新規作成時
    } else {
      await this.getStock(this.stock_id);
      await this.setCreateDefault();
    }

    // 移動先の倉庫リストから移動元の倉庫を省く
    this.destinationWarehouseList = this.warehouseList.filter(item => {
      return item.value != this.stock.warehouse_id;
    });

    //バリデーションチェック
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 新規作成時デフォルト値をセットする
  private async setCreateDefault() {
    this.params.createFromStock(this.stock);
    this.params.status = this.movement_status.movement;
    this.max_quantity = this.stock.quantity;
    await this.productSelected();
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setUpdateDefault() {
    this.params.createFromMovement(this.movement);
    this.params.status = this.movement_status.movement;
    this.params.arrival_date = this.stock.arrival_date;
    this.params.supplier_id = this.stock.supplier_id;
    this.max_quantity = this.stock.quantity + (this.params.quantity || 0);
    await this.productSelected();
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    if (this.movement_id && !this.movement.exists_same_stock_id) {
      result = await this.update({
        movement_id: this.movement_id,
        params: this.params
      });
    }

    if (this.movement_id && this.movement.exists_same_stock_id) {
      result = await this.updateQuantity({
        movement_id: this.movement_id,
        params: this.params
      });
    }

    if (!this.movement_id) {
      result = await this.create(this.params);
    }

    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteMovement(this.movement_id)) {
      this.$router.go(-1);
    }
  }

  //商品が選択された際の挙動
  public async productSelected() {
    const product = this.findProduct(this.params.product_id);
    this.unit = product.unit;
    await this.getLots({
      product_id: this.params.product_id,
      per_page: 0
    });
  }

  // 倉庫移動した在庫に出荷データがあった場合の数量制限
  public changeLimitQuantity() {
    const diff_quantity =
      (this.params.quantity || 0) - (this.movement.quantity || 0);
    if (diff_quantity < 0) {
      return (
        this.movement.current_stock_quantity >= -diff_quantity ||
        "下限数量を下回っています"
      );
    }
    if (diff_quantity > 0) {
      return (
        this.movement.previous_stock_quantity >= diff_quantity ||
        "上限数量を上回っています"
      );
    }
    return true;
  }
}
